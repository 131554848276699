<template>
	<div class="title">
		<div class="left">
			<div>{{name}}</div>
			<div @click="More" v-if="type == 2" class="more">
				<div class="text">{{$t('recommend.more')}}</div>
				<Icon name="icon-7key-musicround-keyboard_arrow-2" theme-color="iconColorAll" width="10" height="10">
				</Icon>
			</div>
		</div>
		<div class="right">
			<div @click="prev">
				<Icon class="left-icon" name="icon-7key-musiczuo"  theme-color="iconColorSwiperBtn" width="30" height="30">
				</Icon>
			</div>
			<div @click="next">
				<Icon class="right-icon" name="icon-7key-musicyou" theme-color="iconColorSwiperBtn" width="30" height="30">
				</Icon>
			</div>
			
			<!-- <img @click="prev" class="left-icon" src="../../assets/images/recommend/left-icon.png" alt="">
			<img @click="next" class="right-icon" src="../../assets/images/recommend/right-icon.png" alt=""> -->
		</div>
	</div>
</template>

<script>
	import {
		setTheme
	} from "@/assets/theme";
	import Icon from "@/components/global/Icon";
	export default {
		props: {
			name: {
				type: String,
				default: ''
			},
			type: {
				type: String,
				default:'1'
			}
		},
		components:{
			Icon
		},
		data() {
			return {

			}
		},
		methods:{
			prev(){
				console.log('111111111111')
				this.$emit('prevChange')
			},
			next(){
				this.$emit('nextChange')
			},
			More(){
				this.$emit('toMore')
				
			}
			
		}
	}
</script>

<style lang="less" scoped>
	.title {
		display: flex;
		// width: 1196px;
		width: 100%;
		height: 97px;
		align-items: center;
		justify-content: space-between;

		.left {
			color: @musicLibraryFilterMenuNavTextColor;
			line-height: 33px;
			display: flex;
			flex: 1;
			font-size: 24px;
			font-weight: 'Semibold';
			color: @musicLibraryFilterMenuNavTextColor;
			align-items: center;
			.more{
				width: 62px;
				height: 26px;
				background: @recommendMusicianListBGColor;
				border-radius: 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 16px;
				font-size: 12px;
				color: @recommendMusicianListTextColor;
				cursor: pointer;
				.text{
					margin-right: 6px;
				}
			}
		}

		.right {
			display: flex;
			flex: 1;
			justify-content: flex-end;

			.left-icon {
				width: 30px;
				height: 30px;
				margin-right: 20px;
				cursor: pointer;
			}

			.right-icon {
				width: 30px;
				height: 30px;
				cursor: pointer;
			}
		}
	}
</style>
