<template>
	<div>
		<PopupCommon ref="popupcommon" customClassName="textarea-modal" title=" " :visible.sync="visible"
			:width="'556px'">
			<div :slot="'title'" class="pop-title1">{{$t('recommend.popupJurisdiction')}}</div>
			<div :slot="'content'" class="pop-content1">
				<div class="content1-title">
					{{$t('recommend.popupJurisdictionContent')}}
				</div>
				<div class="desc-wrap">
					<div @click="enterpriseCertification" class="desc">·{{$t('recommend.enterpriseCertification')}}</div>
					<div @click="musicianCertification" class="desc">·{{$t('recommend.musicianCertification')}}</div>
					<div @click="accountRecharging" class="desc">·{{$t('recommend.accountRecharging')}}</div>
				</div>
		
		
			</div>
			<div :slot="'footer'" class="pop-footer1">
				<div class="cancel1" @click="cancel1">{{$t('recommend.cancel')}}</div>
				<div class="confirm1" @click="confirm1">{{$t('recommend.confirm')}}</div>
			</div>
		</PopupCommon>
	</div>
</template>

<script>
	import PopupCommon from "@/components/popup/PopupCommon.vue"
	export default {
		props: {},
		components: {
			PopupCommon
		},
		data(){
			return {
				visible: false
			}
		},
		methods: {
			open() {
				this.$refs.popupcommon.open()
			},
			cancel1() {
				this.$refs.popupcommon.close()
			},
			confirm1() {
				this.$refs.popupcommon.close()
			},
			// 前往企业认证
			enterpriseCertification(){
				this.$router.push({
					path: '/personalCenter/accountManagement/enterpriseCertification'
				})
			},
			// 音乐人认证
			musicianCertification(){
				this.$router.push({
					path: '/personalCenter/accountManagement/personInformation'
				})
			},
			// 进行账号充值
			accountRecharging(){
				this.$router.push({
					path: '/personalCenter/accountManagement/accountSecurity'
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	// 权限到期提醒弹窗
	.pop-title1 {
		height: 68px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 0 25px;
		color: @recommendMusicianListTextColor;
	}
	
	.pop-content1 {
		max-height: 142px;
		padding: 24px;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 25px;
		color: @recommendMusicianListTextColor;
		line-height: 22px;
	
		.content1-title {
			font-size: 16px;
		}
	
		.desc-wrap {
			margin-top: 12px;
	
			.desc {
				color: #3370FF;
				cursor: pointer;
			}
		}
	
	}
	
	.pop-footer1 {
		display: flex;
		align-items: center;
		height: 68px;
		justify-content: center;
	
		.cancel1 {
			width: 102px;
			height: 36px;
			border-radius: 4px;
			border: 1px solid @popupCancelBorderColor;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: @popupCancelTextColor;
			margin-right: 44px;
			background: @popupCancelBgColor;
			cursor: pointer;
		}
	
		.confirm1 {
			width: 102px;
			height: 36px;
			background: #3278FF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: #FFFFFF;
			cursor: pointer;
		}
	}
	
</style>