<template>
	<div class="main">
		<div class="top">
			<img @click="toDetail(musician.creatorId ? musician.creatorId : musician.id)" :src="musician.headimage" alt="">
		</div>
		<div class="bottom">
			<div class="title">{{musician.name}}</div>
			<div class="desc">{{musician.introduce}}</div>
		</div>
		<div class="works" v-if="type == 1">
			<div>{{$t('recommend.works')}} {{musician.music_count}}</div>
			<!-- <div class="close-icon" v-if="type == 2">
				<Icon name="icon-7key-musicguanbi" theme-color="iconColorAll" width="10" height="10">
				</Icon>
			</div> -->
		</div>
		<div class="works-delete" v-if="type == 2">
			<div @click.stop="delMusician" class="close-icon">
				<Icon name="icon-7key-musicguanbi" theme-color="iconColorAll" width="10" height="10">
				</Icon>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		setTheme
	} from "@/assets/theme";
	export default {
		props: {
			musician: {
				type: Object,
				default () {
					return {}
				}
			},
			type: {
				type: String,
				default: '1'
			}
		},
		data() {
			return {

			}
		},
		methods: {
			// 跳转到详情页
			toDetail(id) {
				console.log(id, 'id')
				this.$router.push({
					path: '/musicianList/musicianPersonalCenter',
					query: {
						creator_id: id
					}
				})
				// this.$emit('toDeatil',id)
				// this.$router.push({
				// 	path: '/MusicianPersonalCenterFinished'
				// })
			},
			// 删除
			delMusician(){
				this.$emit('delMusician')
			}
		},
	}
</script>

<style lang="less" scoped>
	.main {
		background: @recommendMusicianListBGColor;
		color: @recommendMusicianListTextColor;
		// width: 220px;
		width: 100%;
		height: 296px;
		// height: 260px;
		// height: auto;
		border-radius: 6px;
		position: relative;

		.top {
			display: flex;
			align-items: center;
			justify-content: center;
			// width: 220px;
			width: 100%;
			// height: auto;
			height: 220px;
			z-index: 9;
			cursor: pointer;

			img {
				// width: 188px;
				// height: 188px;
				width: 170px;
				height: 170px;
				// width: 100%;
				// height: auto;
				border-radius: 50%;
				z-index: 9;
			}
		}

		.bottom {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.title {
				width: 114px;
				height: 20px;
				font-size: 14px;
				font-weight: 500;
				color: @recommendMusicianListTextColor;
				text-align: center;
				overflow: hidden; // 溢出隐藏
				white-space: nowrap; // 强制一行
				text-overflow: ellipsis; // 文字溢出显示省略号
			}

			.desc {
				width: 180px;
				font-size: 12px;
				color: rgba(255, 255, 255, 0.5);
				text-align: center;
				line-height: 17px;
				color: @recommendMusicianListTextColor;
			}

		}

		.works {
			position: absolute;
			right: 0;
			top: 0;
			width: 76px;
			height: 30px;
			background: @demoitemtabsBGColor;
			border-radius: 0px 6px 0px 10px;
			// backdrop-filter: blur(5px);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			z-index: 10;

			.close-icon {
				font-size: 18px;
				// cursor: pointer;
			}
		}
		.works-delete{
			position: absolute;
			right: 0;
			top: 0;
			width: 76px;
			height: 30px;
			background: @demoitemtabsBGColor;
			border-radius: 0px 6px 0px 10px;
			// backdrop-filter: blur(5px);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			z-index: 10;
			cursor: pointer;
			
			.close-icon {
				font-size: 18px;
				
			}
		}
	}
</style>
