<template>
	<div>
		<PopupCommon ref="lockSongPopup" customClassName="textarea-modal" title=" " :visible.sync="visible" :width="'886px'" @closeDialog="closeDialog">
			<div :slot="'title'" class="pop-title4">{{$t('recommend.payImmediately')}}</div>
			<div :slot="'content'" class="pop-content4">
				<div class="content-top">
					<pay-immediately-top :type="1" :orderInfo="orderInfo" @changeDay="changeDay"></pay-immediately-top>
				</div>
				
				<pay-immediately-bottom :orderInfo="payInfo" @wechatPay="wechatPay" @alipayPay="alipayPay"></pay-immediately-bottom>
			</div>
			<div :slot="'footer'" class="pop-footer4">
				<div v-if="themeStyle == 'default'" class="customr-service" @click="demoCustomerService">
					<a
					  href="http://kf.7keymusic.com/addons/kefu"
					  target="_blank"
					>
						<img src="@/assets/images/recommend/custom-default.png" alt="">
					</a>
				</div>
				<div v-if="themeStyle == 'dark'" class="customr-service" @click="demoCustomerService">
					<a
					  href="http://kf.7keymusic.com/addons/kefu"
					  target="_blank"
					>
						<img src="@/assets/images/recommend/custom-dark.png" alt="">
					</a>
				</div>
				<div class="confirm" @click="demoFinishPay">{{$t('recommend.completePayment')}}</div>
			</div>
		</PopupCommon>
	</div>
</template>

<script>
	import PopupCommon from "@/components/popup/PopupCommon.vue"
	import PayImmediatelyBottom from "@/components/content/PayImmediatelyBottom.vue"
	import PayImmediatelyTop from "@/components/content/PayImmediatelyTop.vue"
	import { musicInfo,buyLockPrice,logo } from "@/api/Home"
	import { getCheckOrder } from "@/api/Mine";
	export default {
		props: {
			orderInfo: {
				type: Object,
				default(){
					return {}
				}
			},
			payInfo: {
				type: Object,
				default(){
					return {}
				}
			}
		},
		components: {
			PopupCommon,
			PayImmediatelyBottom,
			PayImmediatelyTop
		},
		created() {
			// this.getMusicInfo()
			// console.log(this.orderInfo,'payInfo')
			
		},
		data(){
			return {
				visible: false,
				payType: '',//支付方式 1支付宝 2微信
				day: '',//锁定时间
				musicId: '',
				// orderInfo: {
				// 	avatar: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg13.51tietu.net%2Fpic%2F2020010611%2Fnfptpll3xzknfptpll3xzk.jpeg&refer=http%3A%2F%2Fimg13.51tietu.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1670075622&t=7a0a6d98360a03dc014ab446419bbf92',
				// 	name: 'Clarence Walters',
				// 	authorizedTerritory: '大陆',
				// 	authorizationType: '灌录权（灌录权、传播权、复制权）',
				// 	orderSn: '1234567890',
				// 	orderMoney: '30',
				// 	timeList: [{
				// 			time: '3'
				// 		},
				// 		{
				// 			time: '5'
				// 		},
				// 		{
				// 			time: '17'
				// 		}
				// 	]
				// },
				// payInfo: {
				// 	codeImg: 'https://img2.baidu.com/it/u=1989038259,1842360401&fm=253&fmt=auto&app=138&f=PNG?w=500&h=500',
				// 	time: '29分42秒',
				// 	money: '30',
				// 	isWechat: '1', //是否支持微信支付 0不支持 1支持
				// 	isApliy: '1', //是否支持支付宝支付 0不支持 1支持
				// 	isCorporateTransfer: '1', //是否支持对公转账 0不支持 1支持
				// 	corporateName: '名称：沈阳梯基音乐文化传媒有限公司',
				// 	bankDeposit: '建设银行沈阳南京街支行',
				// 	bankAccount: '21050110084600000051'
				
				// },
				locksetinterval: "",
				checksetinterval: "",
				themeStyle: '', //主题 default浅色 dark深色
			}
		},
		methods: {
			// 获取logo图片
			getLogoImg() {
				logo()
					.then((res) => {
						// console.log(res, '获取logo图片');
						this.payInfo.codeImg = res
					})
					.catch((err) => {
						// console.log(err, '获取banner失败');
					});
			},
			closeDialog(){
				console.log('closeDialog');
				this.orderInfo.orderMoney = '';
				this.payInfo.money = "";
				this.payInfo.codeImg = ""
				// this.getLogoImg()
				// this.$store.state.pay_code = '';
				// this.$store.state.pay_price = '';
			},
			getMusicInfo(){
				musicInfo({}).then((res) => {
					console.log(res, '预约价格锁定表');
					this.orderInfo.timeList = res.data.list
				})
				.catch((err) => {
					// console.log(err, '获取banner失败');
				});
			},
			getPayCode(musicId, day, way) {
			  this.locksetinterval && clearTimeout(this.locksetinterval);
			  if (day.length === 0) {
			  } else if (way.length === 0) {
			  } else {
			    this.locksetinterval = setTimeout(() => {
			      let params = {};
			      params.musicId = musicId;
			      params.day = day;
			      params.payType = way;
			
			      buyLockPrice(params).then((res) => {
					  this.orderInfo.orderSn = res.data.order_id
					  this.orderInfo.orderMoney = res.data.price
					  this.payInfo.codeImg = res.data.qr_code
					  this.payInfo.money = res.data.price
			        this.$store.state.pay_code = res.data.qr_code;
			        this.$store.state.pay_price = res.data.price;
			        this.pay = true;
			        let data = {};
			        data.order_id = res.data.order_id;
			        this.checkorder = data.order_id;
			        // this.checksetinterval && clearInterval(this.checksetinterval);
			        // this.checksetinterval = setInterval(() => {
			        //   getCheckOrder(data).then((res) => {
			        //     if (res.status === 1) {
			        //       this.paysuccess = true;
			        //       this.lockDialog = false;
			        //       this.checksetinterval && clearInterval(this.checksetinterval);
			        //       this.paysuccess_data = res.data;
			        //     }
			        //   });
			        // }, 1500);
			      });
			    }, 300);
			
			    //
			  }
			},
			demoCustomerService(){
				this.$refs.lockSongPopup.close()
			},
			demoFinishPay(){
				let data = {};
				data.order_id = this.orderInfo.orderSn
				getCheckOrder(data).then((res) => {
				    if (res.status === 1) {
				      this.paysuccess = true;
				      this.lockDialog = false;
				      this.checksetinterval && clearInterval(this.checksetinterval);
				      this.paysuccess_data = res.data;
					  this.$refs.lockSongPopup.close();
					  this.$router.push("/personalCenter/MyPurchase/Locked");
				    }
				  }).catch((err) => {
					// console.log(err, '获取banner失败');
				});
				
			},
			open(){
				console.log('lockSongOpen')
				this.themeStyle = localStorage.getItem("theme")
				this.$refs.lockSongPopup.open()
			},
			wechatPay(data){
				console.log(data,'wechatPay')
				this.payType = data
				if(this.payType != '' && this.day != ''){
					this.getPayCode(this.orderInfo.musicId,this.day,this.payType)
				}
			},
			alipayPay(data){
				console.log(data,'alipayPay')
				this.payType = data
				if(this.payType != '' && this.day != ''){
					this.getPayCode(this.orderInfo.musicId,this.day,this.payType)
				}
			},
			changeDay(data){
				console.log(data,'changeDay')
				this.day = data
				if(this.payType != '' && this.day != ''){
					this.getPayCode(this.orderInfo.musicId,this.day,this.payType)
				}
			}
			// close(){
			// 	this.$refs.lockSongPopup.close()
			// }
		}
	}
</script>

<style lang="less" scoped>
	::v-deep .el-dialog {
		margin-top: 9vh !important;
	}
	// 锁定demo弹窗
	.pop-title4 {
		height: 68px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 0 25px;
		color: @recommendMusicianListTextColor;
	}
	
	.pop-content4 {
		// max-height: 86px;
		padding: 24px;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 25px;
		color: @recommendMusicianListTextColor;
		line-height: 22px;
		.content-top{
			margin-bottom: 24px;
		}
	}
	
	.pop-footer4 {
		display: flex;
		align-items: center;
		height: 68px;
		justify-content: flex-end;
		padding-right: 20px;
		.customr-service{
			// width: 102px;
			// height: 36px;
			border-radius: 4px;
			border: 1px solid @recommendMusicianListTextColor;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: @popupCancelTextColor;
			margin-right: 44px;
			background: @popupCancelBgColor;
			width: 40px;
			height: 36px;
			border-radius: 4px;
			cursor: pointer;
			// border: 1px solid #FFFFFF;
			img{
				width: 16px;
				height: 16px;
			}
			&:hover{
				// border: 1px solid @recommendMusicianListTextColor;
				position: relative;
				&:after{
					position: absolute;
					content: '联系版权客服';
					left: -50%;
					bottom: -30px;
					background: red;
					color: green;
					width: 92px;
					height: 27px;
					background: rgba(255,255,255,0.7);
					box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
					border-radius: 4px;
					font-size: 12px;
					color: #222426;
					line-height: 27px;
					display: flex;
					justify-content: center;
				}
			}
		}
		.confirm{
			width: 102px;
			height: 36px;
			background: #3278FF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: #FFFFFF;
			cursor: pointer;
		}
	}
</style>