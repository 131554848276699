<template>
	<div class="main">
		<!-- 推荐 头部图片 收藏 开始 -->
		<div class="top">
			<div class="left">
				<swiper style="width: 100%;" v-if="bannerList.length > 0" class="swiper" :options="swiperOption"
					ref="mySwiper">
					<swiper-slide v-for="(item,index) in bannerList" :key="index">
						<a @mouseover="stopAutoPlay(1)" @mouseleave="startAutoPlay(1)" :href="item.url"
							target="_blank"><img class="swiper-img" :src="item.image" alt=""></a>
					</swiper-slide>
				</swiper>
			</div>
			<div class="right">
				<swiper v-if="finishedMusicData.length > 0" style="width: 100%;" class="swiper top-demo"
					:options="swiperTopRightOption" ref="topRightSwiper">
					<!-- <swiper-slide v-for="(item,index) in randMusicData" :key="index">
						<div class="list" style="width: 100%;" @mouseover="stopAutoPlay(2)"
							@mouseleave="startAutoPlay(2)">
							<recommend-top-demo @liking="liking($event,1)" @liked="cancelLike($event,1)" :info="item">
							</recommend-top-demo>
						</div>
					</swiper-slide> -->
					<swiper-slide style="width: 100%;" v-for="(item,index) in randMusicData" :key="index">
						<div class="list" style="width: 100%;" @mouseover="stopAutoPlay(2)"
							@mouseleave="startAutoPlay(2)">
							<recommend-top-demo @liking="liking($event,1)" @liked="cancelLike($event,1)" :info="item">
							</recommend-top-demo>
						</div>
					</swiper-slide>
				</swiper>

			</div>
		</div>
		<!-- 推荐 头部图片 收藏 结束 -->

		<!-- 推荐 成品音乐 开始 -->
		<div class="finished-music">
			<recommend-title :type="'2'" @toMore="toFinishMusician" :name="$t('recommend.finishedMusic')"
				@prevChange="finishMusicPrev" @nextChange="finishMusicNext"></recommend-title>
			<div class="content">
				<swiper class="music-finished-swiper-slide swiper" v-if="finishedMusicData.length > 0" :options="swiperFinishMusicOption"
					ref="finishMusicSwiper">
					<swiper-slide class="music-finished-swiper-slide" v-for="(item,index) in finishedMusicData" :key="index">
						<div class="list">
							<!-- <musician-detail-bottom @toDeatil="toDeatil" @liking="liking" @liked="cancelLike" :obj="item">
							</musician-detail-bottom> -->
							<musician-detail-bottom @liking="liking($event,1)" @liked="cancelLike($event,1)"
								:obj="item">
							</musician-detail-bottom>
						</div>
					</swiper-slide>
				</swiper>

			</div>
		</div>
		<!-- 推荐 成品音乐 结束 -->

		<!-- 推荐 Demo 开始 -->
		<div class="demo">
			<div class="sea">
				<recommend-title :type="'2'" @toMore="toDemoSeaList" :name="$t('recommend.demoSea')"
					@prevChange="DemoseaPrev" @nextChange="DemoseaNext">
				</recommend-title>
				<div class="content">
					<swiper v-if="DemoseaData.length > 0" class="swiper" :options="swiperOptionDemosea"
						ref="demoseaSwiper">
						<swiper-slide v-for="(item,index) in DemoseaData" :key="index">
							<div>
								<RecommendDemoSea @liking="liking($event,2)" @liked="cancelLike($event,2)"
									@toDeatil="toDeatil" @lockSong="lockSong" @buyImmidately="buyImmidately($event,1)"
									:DemoseaList="item"></RecommendDemoSea>
								<!-- <RecommendDemoSea @liking="liking($event,2)" @liked="cancelLike($event,2)" @toDeatil="toDeatil" @buyImmidately="buyImmidately"
									:DemoseaList="item"></RecommendDemoSea> -->
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
			<div class="local">
				<recommend-title :type="'2'" @toMore="toDemoLocalList" :name="$t('recommend.demoLocal')"
					@prevChange="localDemoSwiperPrev" @nextChange="localDemoSwiperNext"></recommend-title>
				<div class="content">
					<swiper v-if="DemolocalData.length > 0" class="swiper" :options="swiperOptionLocalDemo"
						ref="localDemoSwiper">
						<swiper-slide v-for="(item,index) in DemolocalData" :key="index">
							<div>
								<RecommendDemoSea @liking="liking($event,3)" @liked="cancelLike($event,3)"
									@toDeatil="toDeatil" @lockSong="lockSong" @buyImmidately="buyImmidately($event,2)"
									:DemoseaList="item"></RecommendDemoSea>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
		</div>
		<!-- 推荐 Demo 结束 -->

		<!-- 推荐 进入音乐库按钮 开始 -->
		<div @click="intoMusicLibrary" class="musicLibrary">
			<!-- <img src="../../assets/images/recommend/music.png" alt=""> -->
			<Icon name="icon-7key-musicyinyue" theme-color="iconColorAll" width="10" height="10">
			</Icon>
			<div class="text">{{$t('recommend.enterMusicLibrary')}}</div>
		</div>
		<!-- 推荐 进入音乐库按钮 结束 -->

		<!-- 推荐 作品协作区 开始 -->
		<div class="works">
			<recommend-title class="works-title" :type="'2'" @toMore="toWorksList"
				:name="$t('recommend.WorkCollaboration')" @prevChange="worksSwiperPrev" @nextChange="worksSwiperNext">
			</recommend-title>
			<div style="content">
				<!-- <div v-for="(item, index) in worksList" :key="index">
					<DemoSea :DemoseaList="item"></DemoSea>
				</div> -->
				<swiper class="swiper" :options="swiperOptionWorks" ref="worksSwiper">
					<swiper-slide class="works-swiper-slide" v-for="(item,index) in worksData" :key="index">
						<div>
							<!-- <DemoSea  @toDeatil="toDeatil" :DemoseaList="item"></DemoSea> -->
							<DemoSea @liking="liking($event,4)" @liked="cancelLike($event,4)" :DemoseaList="item"
								@openUpload="openUploadPopup"></DemoSea>
						</div>
					</swiper-slide>
				</swiper>
			</div>
		</div>
		<!-- 推荐 作品协作区 结束 -->

		<!-- 推荐 音乐人 开始 -->
		<div class="musician">
			<recommend-title :name="$t('recommend.musician')" @toMore="toMusicianList" :type="'2'"
				@prevChange="musicianSwiperPrev" @nextChange="musicianSwiperNext"></recommend-title>
			<div class="content">
				<swiper v-if="musicianData.length > 0" class="swiper" :options="swiperOptionMusician"
					ref="musicianSwiper">
					<swiper-slide v-for="(item,index) in musicianData" :key="index">
						<div class="list" style="width: 100%;">
							<!-- <recommend-musician @toDeatil="toDeatil" :musician="item"></recommend-musician> -->
							<recommend-musician :musician="item"></recommend-musician>
						</div>
					</swiper-slide>
				</swiper>

			</div>
		</div>
		<!-- 推荐 音乐人 结束 -->

		<!-- 登陆成功 -->
		<!-- <div class="bottomButton" style="">
			<div @click="open"
				style="width:100px;height:50px;background:red;color:#fff;border-radius:16px;margin-right:24px;display:flex;align-items:center;">
				登陆成功</div>
			<div @click="open1"
				style="width:100px;height:50px;background:red;color:#fff;border-radius:16px;margin-right:24px;display:flex;align-items:center;">
				权限到期提醒</div>
			<div @click="open2"
				style="width:100px;height:50px;background:green;color:#fff;border-radius:16px;margin-right:24px;display:flex;align-items:center;">
				对公转账</div>
			<div @click="personalCenter"
				style="width:100px;height:50px;background:green;color:#fff;border-radius:16px;margin-right:24px;display:flex;align-items:center;">
				个人中心</div>
		</div> -->

		<!-- 登陆成功弹窗 -->
		<LoginSucceed ref="popupLogin"></LoginSucceed>

		<!-- 权限到期提醒弹窗 -->
		<PermissionPopup ref="popupPermission"></PermissionPopup>

		<!-- 立即购买 对公转账 弹窗 -->
		<BuyTransferPopup ref="popupTransfer" @completePay="buyMusic1" @getvoucherImage="getvoucherImage"
			@getPayType="getPayType" :orderInfo="orderInfo" :payInfo="payInfo"></BuyTransferPopup>

		<!-- 对公转账失败弹窗 -->
		<!-- <PopupCommon class="transfer" ref="popupcommon3" customClassName="textarea-modal" title=" "
			:visible.sync="visible3" :width="'556px'">
			<div :slot="'title'" class="pop-title3">{{$t('recommend.transferPopupTitle')}}</div>
			<div :slot="'content'" class="pop-content3">
				<div class="content3-title">{{$t('recommend.transferPopupText1')}}：</div>
				<div class="desc-wrap">
					<div class="desc">·{{$t('recommend.transferPopupText2')}}</div>
					<div class="desc">·{{$t('recommend.transferPopupText3')}}</div>
					<div class="desc">·{{$t('recommend.transferPopupText4')}}</div>
				</div>
			</div>
			<div :slot="'footer'" class="pop-footer3">
				<div class="cancel3" @click="cancel3">{{$t('recommend.cancel')}}</div>
				<div class="confirm3" @click="confirm3">{{$t('recommend.confirm')}}</div>
			</div>
		</PopupCommon> -->

		<!-- 立即支付 弹窗 -->
		<PayImmediatelyPopup ref="popupPay" :orderInfo="orderInfo" :payInfo="payInfo"></PayImmediatelyPopup>

		<!-- 锁定demo 弹窗 -->
		<lockDemoPopup ref="lockSongPopup" :orderInfo="orderInfo" :payInfo="payInfo"></lockDemoPopup>

		<!-- 认领伴奏弹窗 -->
		<ClaimBeatPopup @confirm="uploadBeat(uploadInfo)" ref="claimBeatPopup"></ClaimBeatPopup>

		<!-- 立即购买弹窗 -->
		<PopupCommon class="buyPopup" ref="popupbuy" customClassName="textarea-modal" title=" " :visible.sync="visible"
			:width="'986px'" @closeDialog="closeDialog">
			<div :slot="'title'" class="pop-title">{{$t("recommend.payImmediately")}}</div>
			<div :slot="'content'" class="pop-contentbuy">
				<BuySong :musicInfo="musicInfoBuy" @getdiscountPrice="getdiscountPrice" @getlockPrice="getlockPrice"
					@getPrice="getPrice" @authType="authType" :demoType="demoType" @areaType="areaType"
					@changeTime="changeTime" @buyType="getBuyType"></BuySong>
			</div>
			<div :slot="'footer'" class="pop-footerbuy">
				<div class="pop-footerbuy-coupon">
					<div class="price-title">{{$t('price')}}</div>
					<div class="price-text">￥{{Math.ceil(price)}}</div>
					<el-popover placement="bottom" popper-class="popper-coupon" trigger="hover">
						<div class="popover-content">
							<div class="pop-title">{{$t('allpreferential')}}</div>
							<div class="pop-item" style="">
								<div>{{$t('totalcommodityprice')}}</div>
								<div>￥{{Math.ceil(price)}}</div>
							</div>
							<div class="pop-item">
								<div>{{$t('coupondeduction')}}</div>
								<div class="price">￥{{discountPrice}}</div>
							</div>
							<div class="pop-item">
								<div>{{$t('locksonggold')}}</div>
								<div class="price">￥{{lockPrice}}</div>
							</div>
							<div class="pop-item">
								<div>{{$t('Generalpreference')}}</div>
								<div class="price">￥{{lockPrice + discountPrice}}</div>
							</div>
							<div class="pop-item">
								<div>{{$t('total')}}</div>
								<div>￥{{Math.ceil(price)}}</div>
							</div>
						</div>
						<div slot="reference" class="popover-reference">
							<div>{{$t('preferentialdetails')}}</div>
							<i class="el-icon-arrow-down"></i>
						</div>
					</el-popover>
				</div>
				<div class="pop-footerbuy-right">
					<div v-if="themeStyle == 'default'" class="cancelbuy" @click="payCustomerService">
						<a href="http://kf.7keymusic.com/addons/kefu" target="_blank">
							<img src="@/assets/images/recommend/custom-default.png" alt="" />
						</a>
					</div>
					<div v-if="themeStyle == 'dark'" class="cancelbuy" @click="payCustomerService">
						<a href="http://kf.7keymusic.com/addons/kefu" target="_blank">
							<img src="@/assets/images/recommend/custom-dark.png" alt="" />
						</a>
					</div>
					<div class="confirmbuy" @click="payFinishPay">{{ $t('recommend.payImmediately') }}</div>
				</div>

			</div>
		</PopupCommon>
	</div>
</template>

<script>
	// import {swiper, swiperSlide} from 'vue-awesome-swiper'
	import {
		setTheme
	} from "@/assets/theme";
	import MusicianDetailBottom from "@/components/content/MusicianDetailBottom"
	import RecommendDemoSea from "@/components/content/RecommendDemoSea.vue"
	import RecommendTitle from "@/components/content/RecommendTitle.vue"
	import DemoSea from "@/components/content/DemoSea.vue"
	import RecommendMusician from "@/components/content/RecommendMusician.vue"
	import RecommendTopDemo from "@/components/content/RecommendTopDemo.vue"
	import PopupCommon from "@/components/popup/PopupCommon.vue"
	import PayImmediatelyBottom from "@/components/content/PayImmediatelyBottom.vue"
	import PayImmediatelyTop from "@/components/content/PayImmediatelyTop.vue"
	import Icon from "@/components/global/Icon";
	import lockDemoPopup from "@/components/popup/lockDemoPopup.vue"
	import PayImmediatelyPopup from "@/components/popup/PayImmediatelyPopup.vue"
	import LoginSucceed from "@/components/popup/LoginSucceed.vue"
	import PermissionPopup from "@/components/popup/PermissionPopup.vue"
	import BuyTransferPopup from "@/components/popup/BuyTransferPopup.vue"
	import ClaimBeatPopup from "@/components/popup/ClaimBeatPopup.vue"
	import BuySong from "@/components/popup/BuySong.vue";
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'
	import {
		getIndexBanner,
		getTopMusicList,
		getMusicList,
		getCreatorList,
		beatList,
		buyLockPrice,
		LockPrice,
		musicInfo,
		logo,
		getBeat,
		buyMusic,
		randMusic
	} from "@/api/Home";
	import {
		getLiking,
		getLiked,
		myInfo,
		getCheckOrder,

	} from "@/api/Mine";
	import {
		getToken
	} from "@/utils/common";
	import {
		setMyInfo,
		getMyInfo,
	} from "@/utils/common/user";
	export default {
		components: {
			swiper,
			swiperSlide,
			MusicianDetailBottom,
			RecommendDemoSea,
			RecommendTitle,
			DemoSea,
			RecommendMusician,
			RecommendTopDemo,
			PopupCommon,
			PayImmediatelyBottom,
			PayImmediatelyTop,
			Icon,
			lockDemoPopup,
			PayImmediatelyPopup,
			LoginSucceed,
			PermissionPopup,
			BuyTransferPopup,
			ClaimBeatPopup,
			BuySong
		},
		computed: {
			// finishedMusicList() { // 数组分割
			// 	let arr = [];
			// 	let num = 5 // 填写需要分割成几个一组的数量
			// 	if (this.finishedMusicData.length) {
			// 		for (let i = 0; i < this.finishedMusicData.length; i += num) {
			// 			arr.push(this.finishedMusicData.slice(i, i + num));
			// 		}
			// 	}
			// 	return arr;
			// },
			DemoseaList() {
				let arr = [];
				let num = 2 // 填写需要分割成几个一组的数量
				if (this.DemoseaData.length) {
					for (let i = 0; i < this.DemoseaData.length; i += num) {
						arr.push(this.DemoseaData.slice(i, i + num));
					}
				}
				return arr;
			},
			DemolocalList() {
				let arr = [];
				let num = 2 // 填写需要分割成几个一组的数量
				if (this.DemolocalData.length) {
					for (let i = 0; i < this.DemolocalData.length; i += num) {
						arr.push(this.DemolocalData.slice(i, i + num));
					}
				}
				return arr;
			},
			worksList() {
				let arr = [];
				let num = 2 // 填写需要分割成几个一组的数量
				if (this.worksData.length) {
					for (let i = 0; i < this.worksData.length; i += num) {
						arr.push(this.worksData.slice(i, i + num));
					}
				}
				return arr;
			},
			// 音乐人
			musicianList() {
				let arr = [];
				let num = 6 // 填写需要分割成几个一组的数量
				if (this.musicianData.length) {
					for (let i = 0; i < this.musicianData.length; i += num) {
						arr.push(this.musicianData.slice(i, i + num));
					}
				}
				return arr;
			}
		},
		mounted() {
			if (this.getToken()) {
				myInfo().then((res) => {

					// this.userName = res.data.name;
					// this.userImage = res.data.image;
					// this.userIntroduce = res.data.introduce;
					// this.userDay = res.data.day;
					// this.userFollow = res.data.follow_count;
					// this.userFavorite = res.data.favorite_count;
					// this.userShopping = res.data.shopping_cart_count;
					// this.userPurchase = res.data.withdrawal;
					this.$store.state.loginname = res.data.name;
					this.$store.state.userImage = res.data.image;
					this.$store.state.introduce = res.data.introduce
					// this.$store.state.isProve = res.data.is_prove
					this.$store.state.identity = res.data.identity
					this.$store.state.countryId = res.data.countryId
					this.setMyInfo(res.data)
					// console.log(this.getMyInfo(),'getMyInfo111111')
					// console.log(this.$store.state.loginname,'myInfo')
					// this.$store.state.surplus_sum = res.data.surplus;
					// this.$store.state.is_prove = res.data.is_prove;
				});
			}

			// this.getLogoImg()
			// if(this.themeStyle == 'default'){
			// 	console.log('111111111111111111111111111111111111')
			// 	this.payInfo.codeImg = '@/assets/images/main/logo-dark.png'
			// }else if (this.themeStyle == 'dark'){
			// 	console.log('2222222222222222222222222222222222')
			// 	this.payInfo.codeImg = '@/assets/images/main/logo-white.png'
			// }
			
			// setTimeout(() => {
			// 	this.swt = true
			// 	this.bannerSwp = true
			// }, 200)
			// console.log(this.$refs.finishMusicSwiper.swiper.progress,'progress')
		},
		created() {
			this.getBanner(); //获取banner
			this.getTopMusicList(); // 首页成品音乐列表
			this.getMusicList(1); //首页demo音乐列表--海外
			// this.getMusicList(2); //首页demo音乐列表--内地
			this.getMusicList(7); //首页demo音乐列表--内地
			this.getCreatorList(); // 首页推荐制作人列表
			this.getBeatList(); // 首页beat音乐列表
			this.getLockPrice(); // 首页预约锁定价格表
			this.getRandMusic();

		},
		data() {
			return {
				randMusicData: [], //单个轮播图
				musicInfoBuy: {}, //购买弹窗音乐信息
				uploadInfo: {}, //下载编曲对象
				userName: '',
				bannerList: [], //banner列表
				visible: false,
				visible1: false,
				visible2: false,
				visible3: false,
				// banner swiper配置
				swiperOption: { //同swiper
					slidesPerView: 1,
					spaceBetween: 24,
					loop: true,
					autoplay: {
						disableOnInteraction: false,
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				},
				// 成品音乐 swiper配置
				swiperFinishMusicOption: { //同swiper
					slidesPerView: 5,
					spaceBetween: 24,
					slidesPerGroup: 5,
					loop: true,
					// autoplay: {
					// 	disableOnInteraction: false,
					// },
					pagination: {
						el: '.swiper-pagination1',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next1',
						prevEl: '.swiper-button-prev1'
					}
				},
				swiperTopRightOption: { //同swiper
					slidesPerView: 1,
					spaceBetween: 30,
					slidesPerGroup: 1,
					loop: true,
					autoplay: {
						disableOnInteraction: false,
					},
					pagination: {
						el: '.swiper-pagination1',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next1',
						prevEl: '.swiper-button-prev1'
					}
				},
				// 海外demo swiper配置
				swiperOptionDemosea: { //同swiper
					// slidesPerView: 2,
					// slidesPerGroup: 2,
					slidesPerColumn: 3, //竖着展示2张图片
					spaceBetween: 10,
					loop: false,
					// autoplay: {
					// 	disableOnInteraction: false,
					// },
					pagination: {
						el: '.swiper-pagination2',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next2',
						prevEl: '.swiper-button-prev2'
					}
				},
				// 本地demo swiper配置
				swiperOptionLocalDemo: {
					// slidesPerView: 1,
					slidesPerColumn: 3, //竖着展示2张图片
					spaceBetween: 10,
					loop: false,
					pagination: {
						el: '.swiper-pagination3',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next3',
						prevEl: '.swiper-button-prev3'
					}
				},
				// 作品协作区 swiper配置
				swiperOptionWorks: {
					// slidesPerView: 1,
					slidesPerColumn: 3, //竖着展示2张图片
					// slidesPerGroup: 2,
					spaceBetween: 10,
					loop: true,
					pagination: {
						el: '.swiper-pagination4',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next4',
						prevEl: '.swiper-button-prev4'
					}
				},
				// 音乐人 swiper配置
				swiperOptionMusician: {
					slidesPerView: 5,
					spaceBetween: 24,
					slidesPerGroup: 5,
					loop: true,
					pagination: {
						el: '.swiper-pagination5',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next5',
						prevEl: '.swiper-button-prev5'
					}
				},

				orderInfo: {
					avatar: '',
					name: '',
					musicId: '',
					// authorizedTerritory: '大陆',
					// authorizationType: '灌录权（灌录权、传播权、复制权）',
					orderSn: '',
					orderMoney: '',
					timeList: [{
							time: '3'
						},
						{
							time: '5'
						},
						{
							time: '17'
						}
					]
				},
				payInfo: {
					codeImg: '',
					time: 10 * 60,
					money: '',
					isWechat: '1', //是否支持微信支付 0不支持 1支持
					isApliy: '1', //是否支持支付宝支付 0不支持 1支持
					isCorporateTransfer: '1', //是否支持对公转账 0不支持 1支持
					corporateName: this.$t("namecomponent") + this.$t("tranfercomponentinfo"),
					bankDeposit: this.$t("bankaccountinfo"),
					bankAccount: '21050110084600000051'

				},
				themeStyle: localStorage.getItem("theme"), //主题 default浅色 dark深色
				topInfo: {
					bgImg: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg13.51tietu.net%2Fpic%2F2020010611%2Fnfptpll3xzknfptpll3xzk.jpeg&refer=http%3A%2F%2Fimg13.51tietu.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1670075622&t=7a0a6d98360a03dc014ab446419bbf92',
					title: 'Clarence Walters',
					song: '易烊千玺'
				},
				musicianData: [],
				worksData: [],

				DemoseaData: [],
				// DemoseaData1: [
				// 	{
				// 		title: "111",
				// 		genrelist: [
				// 			{
				// 				name: "哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈哈哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈哈哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈哈哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈哈哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈哈哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈哈哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈哈哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈哈哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈哈哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈哈哈哈哈"
				// 			},
				// 			{
				// 				name: "哈哈哈哈哈哈哈哈"
				// 			}
				// 		]
				// 	}
				// ],
				DemolocalData: [],
				finishedMusicData: [], // 页面歌曲数据
				bannerSwp: false,

				pageFinish: 1, //成品音乐页数
				pageSizeFinish: 20, //成品音乐每页5条
				countFinish: '', //成品音乐总数
				swt: false,
				price: 0,
				payType: '',
				discountPrice: 0,
				lockPrice: 0,
				voucherImage: '',
				couponId: '',
				demoType: '', //1海外 2内地
			}
		},
		watch: {
			payType: {
				handler(newValue, oldValue) {
					// console.log('themeName11111', newValue)
					// this.themeName = newValue
					this.buyMusic()
					// console.log(this.GlobalCSS[newValue])
					// this.$refs.iconFvg.style.fill = this.GlobalCSS[newValue]
				}
			},
		},
		methods: {
			getToken,
			setMyInfo,
			getMyInfo,
			closeDialog(){
				console.log('closeDialog');
				this.price = "";
			},
			getdiscountPrice(data) {
				console.log(data, 'getdiscountPrice')
				this.discountPrice = data.discountPrice
				this.couponId = data.discountValue
			},
			// 单个轮播
			getRandMusic() {
				randMusic().then((res) => {
					this.randMusicData = res.data
				});
			},
			// 获取锁定价格
			getlockPrice(data) {
				// console.log(data,'获取锁定价格')
				this.lockPrice = data
			},
			getPrice(data) {
				// console.log(data,'data')
				this.price = data

			},
			getBuyType(data) {
				// console.log(data,'buyType')
				this.buyType = data
			},
			areaType(data) {
				// console.log(data,'areaType')
				this.orderInfo.authorizedTerritory = data
				this.authorizationArea = data
			},
			changeTime(data) {
				// console.log(data,'changeTime')
				this.yearId = data
			},
			authType(data) {
				// console.log(data,'authType')
				this.authorizationType = data.name
				this.orderInfo.authorizationType = data.name + '('
				let arr = data.details
				// arr.map(item => {
				// 	console.log(item,'isisisissisi')
				// 	// this.orderInfo.authorizationType += item.name
				// })
				for (var key in arr) {
					// console.log(key,'iiiiiiiiiiiiiiiiiii')
					this.orderInfo.authorizationType = this.orderInfo.authorizationType + arr[key].name
					// console.log(this.orderInfo.authorizationType)
				}
				this.orderInfo.authorizationType = this.orderInfo.authorizationType + ')'
			},
			//购买单个音乐
			buyMusic() {
				let params = {};
				params.musicId = this.musicInfoBuy.musicId; //音乐id
				params.payType = this.payType; //支付类型
				params.authorizationType = this.authorizationType; //授权类型
				params.authorizationRegion = this.authorizationArea; //授权地区
				params.authorizationDuration = this.yearId; //授权时间
				// params.lrcId = cId;
				params.couponId = this.couponId;
				params.voucher_img = this.voucherImage;
				params.lockPrice = this.lockPrice;
				if(this.demoType == 2){
					params.is_beat = this.buyType;
				}
				
				// console.log(params,'paramsparams');
				buyMusic(params).then((res) => {
					if (res.status != 1) {
						this.$message({
							type: "error",
							message: res.msg,
						});
						return;
					}
					if (this.payType == 4) {
						this.$message({
							type: "success",
							message: this.$t("orderhascreate"),
						});
					}
					this.payInfo.codeImg = res.data.qr_code;
					this.orderInfo.orderSn = res.data.order_id;
					// this.buyType = ''
					// let data = {};
					// data.order_id = res.data.order_id;
					// this.$refs.popupTransfer.close()
					// let test = setInterval(() => {
					//   getCheckOrder(data).then((res) => {
					//     if (!(res.status === -1)) {
					//       this.$message({
					//         type: "success",
					//         message: "支付成功",
					//       });
					//       clearInterval(test);
					// this.$refs.popupTransfer.close()
					//       // this.handleClose_cart();
					//       // this.$router.push("/personal/mypurchased");
					//     }
					//   });
					// }, 1000);
				});
			},
			buyMusic1() {
				let data = {};
				data.order_id = this.orderInfo.orderSn;
				// this.$refs.popupTransfer.close()
				getCheckOrder(data).then((res) => {
					if (!(res.status === -1)) {
						this.$message({
							type: "success",
							message: this.$t("paysuccess"),
						});
						this.$refs.popupTransfer.close()
						this.$router.push("/personalCenter/MyPurchase/Purchased");
						// this.handleClose_cart();
						// this.$router.push("/personal/mypurchased");
					}
				});
			},
			// 获取支付凭证
			getvoucherImage(data) {
				this.voucherImage = data
			},
			// 获取支付类型
			getPayType(data) {
				console.log(data, 'wqwerttyyuiooppp[[[]]]')
				this.payType = data
			},
			// 获取logo图片
			getLogoImg() {
				logo()
					.then((res) => {
						// console.log(res, '获取logo图片');
						this.payInfo.codeImg = res
					})
					.catch((err) => {
						// console.log(err, '获取banner失败');
					});
			},
			// 预约价格锁定表
			getLockPrice() {
				LockPrice()
					.then((res) => {
						// console.log(res, '预约价格锁定表');
						this.orderInfo.timeList = res.data.list
					})
					.catch((err) => {
						// console.log(err, '获取banner失败');
					});
			},
			// 获取banner
			getBanner() {
				getIndexBanner()
					.then((res) => {
						// console.log(res, '获取banner成功');
						this.bannerList = res.data.list
					})
					.catch((err) => {
						// console.log(err, '获取banner失败');
					});
			},
			// 首页成品音乐列表
			getTopMusicList(type) {
				console.log(this.pageFinish, 'getTopMusicList')
				getTopMusicList({
						page: this.pageFinish,
						pageSize: this.pageSizeFinish
					}).then((res) => {
						// console.log(res, '首页成品音乐列表成功');
						this.countFinish = res.data.count
						// this.finishedMusicData = []
						// this.finishedMusicData = res.data.list

						if (this.pageFinish == 1) {
							console.log(111111111)
							this.finishedMusicData = res.data.list
						} else if (this.pageFinish > Math.ceil(this.countFinish / this.pageSizeFinish)) {
							console.log(22222222)
							this.finishedMusicData = []
						} else if ((this.pageFinish <= Math.ceil(this.countFinish / this.pageSizeFinish)) && (this
								.pageFinish > 1)) {
							console.log(333333333)
							this.finishedMusicData = this.finishedMusicData.concat(res.data.list)
						}
						console.log(this.finishedMusicData, 'finishedMusicData')
						this.swt = true
						// this.pageFinish++
						// if(type == 1){
						// 	this.pageFinish++
						// 	this.$refs.finishMusicSwiper.swiper.slideNext();
						// }
					})
					.catch((err) => {
						console.log(err, '首页成品音乐列表失败');
					});
			},
			// 首页demo音乐列表
			getMusicList(type) {
				getMusicList({
						page: 1, //页数
						pageSize: 21,
						min_bmp: '', //最小bmp
						max_bmp: '', //最大bmp
						bmp: '', //节拍(精准)
						key: '', //曲调id
						instruments: '', //乐器id
						genres: '', //风格id
						title: '', //搜索标题
						type: '', //搜索方式:1:歌曲名称搜索;2:制作人搜索; title 为搜索内容
						recommend: type, //1海外 2内地
					}).then((res) => {
						// console.log(res, '首页demo音乐列表成功');
						// this.finishedMusicData = res.data.list
						if (type == 1) {
							this.DemoseaData = res.data.list
						} else if (type == 7) {
							this.DemolocalData = res.data.list
						}

					})
					.catch((err) => {
						console.log(err, '首页成品音乐列表失败');
					});
			},
			// 首页推荐制作人列表
			getCreatorList() {
				getCreatorList({
						page: 1,
						pageSize: 20
					}).then((res) => {
						// console.log(res, '获取推荐制作人列表成功');
						this.musicianData = res.data.list
						// this.numMusician = res.data.count
						// console.log(res.data.count,'res.data.list.count')
					})
					.catch((err) => {
						console.log(err, '获取推荐制作人列表失败');
					});
			},
			// 首页beat音乐列表
			getBeatList() {
				beatList({
						// creator_id: creator_id,
						min_bmp: '',
						max_bmp: '',
						bmp: '',
						key: '', //音调对应的键值
						Instruments: '', //仪器对应的键值
						genres: '', //体裁对应的键值
						title: '', //标题查找
						type: '', //搜索方式:1:歌曲名称搜索;2:制作人搜索; title 为搜索内容
						page: 1, //页数:默认1
						pageSize: 21
						// recommend: 1,//成品音乐1 海外是2 内地是7
					}).then((res) => {
						// console.log(res, '获取推荐制作人列表成功');
						let arr = []
						arr = res.data.list.map(item => {
							return {
								...item,
								titlecontent: '编曲认领'
							}

						})
						this.worksData = arr
						// this.numMusician = res.data.count
						// console.log(res.data.count,'res.data.list.count')
					})
					.catch((err) => {
						console.log(err, '获取推荐制作人列表失败');
					});
			},
			// 打开下载弹窗
			openUploadPopup(data) {
				console.log(data, 'openUploadPopup')
				this.uploadInfo = data
				getBeat({
						music_id: data.musicId
					}).then((res) => {
						console.log(res, '编曲认领成功');
						if (res.status == 1) {
							// this.$message.success(res.msg)
							// this.$refs.claimBeatPopup.open()
							this.$refs.claimBeatPopup.open()
							this.getBeatList()
							// this.$emit('openUpload', item)
						} else {
							this.$message.error(res.msg)
						}
					})
					.catch((err) => {
						console.log(err, '编曲认领失败');
					});

				// this.$router.go(0)
				// this.getBeatList()
			},
			// 下载歌曲
			downloadFile(content, filename) {
				var a = document.createElement("a");
				var blob = new Blob([content]);
				var url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = filename;
				a.click();
				window.URL.revokeObjectURL(url);
				this.$refs.claimBeatPopup.close()
			},
			ajax(url, callback, options) {
				window.URL = window.URL || window.webkitURL;
				var xhr = new XMLHttpRequest();
				xhr.open("get", url, true);
				if (options.responseType) {
					xhr.responseType = options.responseType;
				}
				xhr.onreadystatechange = function() {
					if (xhr.readyState === 4 && xhr.status === 200) {
						callback(xhr);
					}
				};
				xhr.send();
			},
			// 下载编曲
			uploadBeat(file) {
				var url = file.download;
				console.log(file);
				let _this = this;
				this.ajax(
					url,
					function(xhr) {
						var filename = file.title + "." + file.format; // 自定义文件名+后缀
						_this.downloadFile(xhr.response, filename);
					}, {
						responseType: "blob",
					}
				);
			},
			// 添加喜欢
			liking(data, type) {
				console.log(data, type, 'dtata')
				getLiking({
						musicId: data
					}).then((res) => {
						console.log(res, '添加喜欢成功');
						if (res.status == 1) {
							this.$message.success(res.msg)
						} else {
							this.$message.error(res.msg)
						}
						console.log(type, 'type')
						if (type == 1) {
							this.getTopMusicList();
						} else if (type == 2) {
							this.getMusicList(1);
						} else if (type == 3) {
							// this.getMusicList(2);
							this.getMusicList(7);
						} else if (type == 4) {
							this.getBeatList();
						}

					})
					.catch((err) => {
						console.log(err, '添加喜欢失败');
					});
			},
			// 取消喜欢
			cancelLike(data, type) {
				getLiked({
						musicId: data
					}).then((res) => {
						console.log(res, '取消喜欢成功');
						if (res.status == 1) {
							this.$message.success(res.msg)
						} else {
							this.$message.error(res.msg)
						}
						// this.getTopMusicList()
						if (type == 1) {
							this.getTopMusicList();
						} else if (type == 2) {
							this.getMusicList(1);
						} else if (type == 3) {
							// this.getMusicList(2);
							this.getMusicList(2);
						} else if (type == 4) {
							this.getBeatList();
						}
					})
					.catch((err) => {
						console.log(err, '取消喜欢失败');
					});
			},
			// 进入音乐库
			intoMusicLibrary() {
				console.log('进入音乐库')
				this.$router.push({
					path: '/musicLibrary'
				})
			},
			// 登陆成功弹窗
			open() {
				// this.visible = !this.visible
				this.$refs.popupLogin.open()
			},
			// cancel() {
			// 	this.$refs.popupcommon.close()
			// },
			// confirm() {
			// 	this.$refs.popupcommon.close()
			// },
			// 权限提醒弹窗
			open1() {
				// this.visible = !this.visible
				this.$refs.popupPermission.open()
			},
			// cancel1() {
			// 	this.$refs.popupcommon1.close()
			// },
			// confirm1() {
			// 	this.$refs.popupcommon1.close()
			// },

			// 对公转账弹窗
			open2() {
				this.themeStyle = localStorage.getItem("theme")
				// this.visible = !this.visible
				this.$refs.popupTransfer.open()
			},
			demoCustomerService() {
				this.$refs.popupcommon2.close()
			},
			traferFail() {
				// this.$refs.popupcommon2.close()
				this.$refs.popupcommon3.open()
			},

			// 对公转账 失败 弹窗
			cancel3() {
				this.$refs.popupcommon3.close()
			},
			confirm3() {
				this.$refs.popupcommon3.close()
			},
			//鼠标移入暂停自动播放
			stopAutoPlay(type) {
				if (type == 1) {
					this.$refs.mySwiper.swiper.autoplay.stop();
					console.log(this.$refs.mySwiper.swiper, 'stopAutoPlay')
				} else if (type == 2) {
					this.$refs.topRightSwiper.swiper.autoplay.stop();
				}
				// console.log(this.$refs.mySwiper.swiper,'stopAutoPlay')
				// this.currentSwiper.autoplay.stop()

			},
			//鼠标移出开始自动播放
			startAutoPlay(type) {
				if (type == 1) {
					this.$refs.mySwiper.swiper.autoplay.start()
				} else if (type == 2) {
					this.$refs.topRightSwiper.swiper.autoplay.start()
				}

			},
			// banner swiper 切换
			prev() {
				// console.log(this.$refs.mySwiper.swiper.slidePrev(), 'prev')
				this.$refs.mySwiper.swiper.slidePrev();
			},
			next() {
				// console.log('next')
				this.$refs.mySwiper.swiper.slideNext()
			},
			// 成品音乐swiper切换
			finishMusicPrev() {
				console.log('finishMusicPrev')
				this.$refs.finishMusicSwiper.swiper.slidePrev();
			},
			finishMusicNext() {
				console.log('finishMusicNext')
				this.$refs.finishMusicSwiper.swiper.slideNext();
			},
			// 海外Demo swiper切换
			DemoseaPrev() {
				console.log('prev')
				this.$refs.demoseaSwiper.swiper.slidePrev();
			},
			DemoseaNext() {
				console.log(this.$refs.demoseaSwiper.swiper.slideNext(), 'next')
				this.$refs.demoseaSwiper.swiper.slideNext();
			},
			// 本地demo swiper切换
			localDemoSwiperPrev() {
				this.$refs.localDemoSwiper.swiper.slidePrev();
			},
			localDemoSwiperNext() {
				this.$refs.localDemoSwiper.swiper.slideNext();
			},
			// 作品协作区 swiper切换
			worksSwiperPrev() {
				this.$refs.worksSwiper.swiper.slidePrev();
			},
			worksSwiperNext() {
				this.$refs.worksSwiper.swiper.slideNext();
			},
			// 音乐人 swiper切换
			musicianSwiperPrev() {
				this.$refs.musicianSwiper.swiper.slidePrev();
			},
			musicianSwiperNext() {
				this.$refs.musicianSwiper.swiper.slideNext();
			},
			// 锁定demo弹窗
			lockSong(data) {
				console.log(data, 'data')
				// this.orderInfo.musicId = data.musicId
				this.themeStyle = localStorage.getItem("theme")
				// this.$refs.lockSongPopup.open()
				musicInfo({
						musicId: data.musicId
					}).then((res) => {
						console.log(res.data.image, 'musicInfo');
						this.orderInfo.avatar = res.data.image
						this.orderInfo.name = res.data.title
						this.orderInfo.orderSn = res.data.order_id
						this.orderInfo.musicId = res.data.musicId
						// this.orderInfo.orderMoney = res.data.
						this.$refs.lockSongPopup.open()



					})
					.catch((err) => {
						// console.log(err, '获取banner失败');
					});





			},
			// 锁定demo弹窗 客服
			demoCustomerService() {
				this.$refs.lockSongPopup.demoCustomerService()
			},
			// 锁定demo弹窗 立即支付
			demoFinishPay() {
				this.$refs.lockSongPopup.demoFinishPay()
			},
			// 立即支付弹窗
			buyImmidately(data, type) {
				console.log(data, type, 'mmmmmmmmmmmmmmmmmmmm')
				this.demoType = type
				console.log(data, 'jjsadjaodsdosajkdaskdjsdjksakjdkj')
				this.themeStyle = localStorage.getItem("theme")
				// this.$refs.popupPay.open()
				this.musicInfoBuy = data
				this.$refs.popupbuy.open()
			},
			// 立即支付弹窗 客服
			payCustomerService() {
				this.$refs.popupPay.close()
			},
			// // 立即支付弹窗 完成支付
			// payFinishPay() {
			// 	this.$refs.popupPay.close()

			// },
			// 立即支付弹窗 完成支付
			payFinishPay() {
				// this.$refs.popupbuy.close();
				// this.getLogoImg()
				this.$refs.popupTransfer.open()

				// console.log(this.musicInfo,'完成支付')
				this.orderInfo.avatar = this.musicInfoBuy.image
				this.orderInfo.name = this.musicInfoBuy.title
				this.orderInfo.orderMoney = this.price
				this.payInfo.money = this.price
			},
			// 去音乐库成品音乐列表
			toFinishMusician() {
				this.$router.push({
					path: '/musicLibrary/producthmusic'
				})
			},
			// 去音乐库demoSea列表
			toDemoSeaList() {
				this.$router.push({
					path: '/musicLibrary/seademo'
				})
			},
			// 去音乐库demo本地列表
			toDemoLocalList() {
				this.$router.push({
					path: '/musicLibrary/inlanddemo'
				})
			},
			// 去音乐库作品协作区列表
			toWorksList() {
				this.$router.push({
					path: '/musicLibrary/workcollaboration'
				})
			},
			// 去音乐人列表
			toMusicianList() {
				this.$router.push({
					path: '/recommend/musicianList'
				})
			},
			// 去个人中心页面
			personalCenter() {
				this.$router.push({
					path: '/personalCenter/myWallet/totalRevenue'
				})
			},
			// 音乐人详情
			toDeatil(data) {
				console.log(data, '详情')
				this.$router.push({
					path: '/musicianList/musicianPersonalCenter',
					query: {
						creator_id: data
					}
				})
			},
		}
	}
</script>


<style lang="less" scoped>
	.music-finished-swiper-slide{
		height: 296px !important;
	}
	::v-deep .swiper .works-swiper-slide {
		margin-top: 0px !important;
	}

	::v-deep .works .works-title {
		margin-bottom: -24px !important;
	}

	// ::v-deep .buyPopup .el-dialog {
	// 	margin-top: 9vh !important;
	// }

	// ::v-deep .el-dialog--center{
	// 	margin-top: 25vh;
	// }
	.main {
		// margin-top: 24px;

		.top {
			// margin-top: 24px;
			display: flex;
			width: 100%;
			justify-content: space-between;
			flex: 0.8 auto;

			.left {
				// margin-right: 24px;
				width: 78%;

				// width: 922px;
				// flex: 0.7;
				// ::v-deep .main .finished-music .content .swiper-slide{
				// 	background-color: @recommendMusicianListBGColor;
				// }
				.swiper {
					height: 300px;
					width: 100%;
				}

				.swiper-slide {
					width: 100%;
					display: flex;
					// justify-content: center;
					align-items: center;
					text-align: center;
					font-weight: bold;
					font-size: 16px;

					// background-color: @recommendMusicianListBGColor;
					img {
						width: 100%;
						// height: 300px;
						aspect-ratio: 858/300 !important;
						// width: 76.83rem;
						// height: 29.17rem;
					}
				}

				// .swiper-img{
				// 	width: 100%;
				// 	height: 100%;
				// }



			}

			.right {
				cursor: pointer;
				width: 20%;
				// width: 250px;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				// flex: 0.3;
				img {
					// width: 20.83rem;
					// height: 29.17rem;
					// width: 250px;
					width: 100%;
					height: 300px;
					background: rgba(34, 36, 38, 0.1);
					border-radius: 6px;
				}
			}

			// .top-demo{
			::v-deep .top-demo .swiper-container {
				border-radius: 6px !important;
			}

			// }
		}

		.finished-music {

			.content {
				display: flex;
				// width: 1196px;
				flex-wrap: wrap;
				// height: 380px;

				justify-content: space-between;

				.swiper {
					height: 276px;
					width: 100%;
					display: flex;
					justify-content: space-between;
				}

				.swiper-slide {

					display: flex;
					height: auto;
					// justify-content: space-between;
					align-items: center;
					// text-align: center;
					font-weight: bold;
					font-size: 16px;
					background-color: @baseBackgroundColor;

					::v-deep .list .main {
						height: 296px;
					}

					::v-deep .list .main .top {
						// height: 67% !important;
						// height: 178px;
						height: 200px;
					}

					.list {
						// margin-right: 26px;
						margin-right: auto;
						// margin-left: auto;
						width: 100%;
						// height: auto;
						// width: 220px;
						height: 296px;
						display: flex;
						// justify-content: center;
						// margin-right: 58px;
					}
				}
			}
		}

		// //当屏幕最小宽度为992px 
		// @media screen and (min-width : 992px) {
		//     body {
		//         属性: 值
		//     }
		// }
		// //当屏幕宽度 最小为768px 最大为991px时 
		// @media screen and (min-width:768px) and (max-width:991px) {
		//     body {
		//        属性: 值
		//     }
		// }
		// //当屏幕最大宽度为767px 
		// @media screen and (max-width:767px) {
		//     body {
		//        属性: 值 
		//     }
		// }
		.demo {
			display: flex;
			justify-content: space-between;
			width: 100%;

			.sea {
				// flex: 1;
				width: 49.2%;

				.content {
					width: 100%;

					::v-deep .swiper-container {
						margin-left: 0 !important;
					}

					.swiper {
						height: 100%;
						width: 100%;
					}

					.swiper-slide {
						display: flex;
						flex-direction: column;
						// justify-content: space-between;
						// align-items: center;
						// text-align: center;
						font-weight: bold;
						font-size: 16px;
						background-color: @baseBackgroundColor;

						.list {
							// margin-right: 24px;
							width: 100%;
							display: flex;
							justify-content: center;
						}
					}
				}

				.title {
					height: 97px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.left {
						color: @musicLibraryFilterMenuNavTextColor;
						line-height: 33px;
						display: flex;
						flex: 1;
						font-size: 24px;
						font-weight: 'Semibold';
						color: @musicLibraryFilterMenuNavTextColor;
					}

					.right {
						display: flex;
						flex: 1;
						justify-content: flex-end;

						.left-icon {
							width: 30px;
							height: 30px;
							margin-right: 20px;
						}

						.right-icon {
							width: 30px;
							height: 30px;
						}
					}
				}
			}

			.local {
				// flex: 1;
				width: 49.2%;

				.content {
					width: 100%;

					::v-deep .swiper-container {
						margin-left: 0 !important;
					}

					.swiper {
						height: 100%;
						width: 100%;
					}

					.swiper-slide {
						display: flex;
						flex-direction: column;
						// justify-content: space-between;
						// align-items: center;
						// text-align: center;
						font-weight: bold;
						font-size: 16px;
						background-color: @baseBackgroundColor;

						.list {
							// margin-right: 24px;
							width: 798px;
							display: flex;
							justify-content: center;
						}
					}
				}

				.title {
					height: 97px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.left {
						color: @musicLibraryFilterMenuNavTextColor;
						line-height: 33px;
						display: flex;
						flex: 1;
						font-size: 24px;
						font-weight: 'Semibold';
						color: @musicLibraryFilterMenuNavTextColor;
					}

					.right {
						display: flex;
						flex: 1;
						justify-content: flex-end;

						.left-icon {
							width: 30px;
							height: 30px;
							margin-right: 20px;
						}

						.right-icon {
							width: 30px;
							height: 30px;
						}
					}
				}
			}
		}



		.musicLibrary {
			width: 190px;
			height: 36px;
			border-radius: 6px;
			border: 1px solid @recommendMusicianListTextColor;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: @recommendMusicianListTextColor;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 20px auto;
			cursor: pointer;

			.text {
				margin-left: 8px;
			}

		}

		.works {}

		.musician {
			margin-bottom: 37px;

			.content {
				display: flex;
				flex-wrap: wrap;

				// justify-content: space-between;
				.swiper {
					height: 300px;
					width: 100%;
				}

				.swiper-slide {
					display: flex;
					width: 100%;
					// justify-content: space-between;
					align-items: center;
					text-align: center;
					font-weight: bold;
					font-size: 16px;
					background-color: @baseBackgroundColor;

					.list {
						// margin-right: 58px;
						margin-right: auto;
						margin-left: auto;
						width: 220px;
						height: 296px;
						// width: 20%;
						display: flex;
						// justify-content: center;
					}
				}
			}
		}

		//当屏幕最小宽度为992px
		@media screen and (min-width : 992px) {
			.bottomButton {
				display: flex;
				flex-direction: row;
			}
		}

		//当屏幕宽度 最小为768px 最大为991px时 
		@media screen and (min-width:768px) and (max-width:991px) {
			.bottomButton {
				display: flex;
				flex-direction: row;
			}
		}

		//当屏幕最大宽度为767px 
		@media screen and (max-width:767px) {
			.bottomButton {
				display: flex;
				flex-direction: column;
			}
		}


		// 登录成功弹窗
		.pop-title {
			height: 68px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid @demoitemtabsBGColor;
			padding: 0 25px;
			color: @recommendMusicianListTextColor;
		}

		.pop-content {
			max-height: 86px;
			padding: 24px;
			border-bottom: 1px solid @demoitemtabsBGColor;
			padding: 25px;
			color: @recommendMusicianListTextColor;
			line-height: 22px;
		}

		.pop-footer {
			display: flex;
			align-items: center;
			height: 68px;
			justify-content: center;

			.cancel {
				width: 102px;
				height: 36px;
				border-radius: 4px;
				border: 1px solid @popupCancelBorderColor;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: @popupCancelTextColor;
				margin-right: 44px;
				background: @popupCancelBgColor;
			}

			.confirm {
				width: 102px;
				height: 36px;
				background: #3278FF;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: #FFFFFF;
			}
		}

		// 权限到期提醒弹窗
		.pop-title1 {
			height: 68px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid @demoitemtabsBGColor;
			padding: 0 25px;
			color: @recommendMusicianListTextColor;
		}

		.pop-content1 {
			max-height: 142px;
			padding: 24px;
			border-bottom: 1px solid @demoitemtabsBGColor;
			padding: 25px;
			color: @recommendMusicianListTextColor;
			line-height: 22px;

			.content1-title {
				font-size: 16px;
			}

			.desc-wrap {
				margin-top: 12px;

				.desc {
					color: #3370FF;
				}
			}

		}

		.pop-footer1 {
			display: flex;
			align-items: center;
			height: 68px;
			justify-content: center;

			.cancel1 {
				width: 102px;
				height: 36px;
				border-radius: 4px;
				border: 1px solid @popupCancelBorderColor;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: @popupCancelTextColor;
				margin-right: 44px;
				background: @popupCancelBgColor;

			}

			.confirm1 {
				width: 102px;
				height: 36px;
				background: #3278FF;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: #FFFFFF;
			}
		}

		// 立即购买 对公转账 弹窗
		.pop-content2 {
			// max-height: 142px;
			padding: 24px;
			border-bottom: 1px solid @demoitemtabsBGColor;
			padding: 25px;
			color: @recommendMusicianListTextColor;
			line-height: 22px;

			.content-top {
				margin-bottom: 24px;
			}
		}

		.pop-footer2 {
			display: flex;
			align-items: center;
			height: 68px;
			justify-content: flex-end;
			padding-right: 20px;

			.cancel {
				// width: 102px;
				// height: 36px;
				border-radius: 4px;
				border: 1px solid @recommendMusicianListTextColor;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: @popupCancelTextColor;
				margin-right: 44px;
				background: @popupCancelBgColor;
				width: 40px;
				height: 36px;
				border-radius: 4px;

				// border: 1px solid #FFFFFF;
				img {
					width: 16px;
					height: 16px;
				}
			}

			.confirm {
				width: 102px;
				height: 36px;
				background: #3278FF;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: #FFFFFF;

			}
		}

		// 立即购买弹窗
		.pop-title {
			height: 68px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid rgba(34, 36, 38, 0.06);
			padding: 0 25px;
			color: @recommendMusicianListTextColor;
			font-size: 16px;
		}

		.pop-content {
			// height: 600px;
			max-height: 164px;
			padding: 24px;
			border-bottom: 1px solid rgba(34, 36, 38, 0.06);
			padding: 25px;
			color: @recommendMusicianListTextColor;

			.content-top {
				margin-bottom: 24px;
			}

			.con-top {
				width: 90px;
				height: 90px;
				margin: auto;

				img {
					width: 90px;
					height: 90px;
					margin: auto;
				}
			}

			.pop-contentbuy {
				height: 600px;
				padding: 24px;
				border-bottom: 1px solid rgba(34, 36, 38, 0.06);
				padding: 25px;
				color: @recommendMusicianListTextColor;
			}

			.con-center {
				width: 280px;
				height: 28px;
				font-size: 20px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: @recommendMusicianListTextColor;
				line-height: 28px;
				margin: auto;
				margin-top: 24px;
			}

			.con-centerpay {
				width: 230px;
				height: 28px;
				font-size: 20px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: @recommendMusicianListTextColor;
				line-height: 28px;
				margin: auto;
				margin-top: 24px;
			}

			.con-bottom {
				width: 210px;
				margin: auto;
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: @popupgray;
				line-height: 20px;
				margin-top: 8px;
			}
		}

		.pop-footerbuy {
			display: flex;
			align-items: center;
			// height: 368px;
			height: 68px;
			justify-content: space-between;
			// padding-right: 20px;
			margin-left: 265px;
			width: calc(100% - 265px);

			.pop-footerbuy-coupon {
				display: flex;
				padding-left: 10px;
				align-items: center;
				height: 36px;

				.price-title {
					width: 28px;
					height: 20px;
					font-size: 14px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: @recommendMusicianListTextColor;
					line-height: 20px;
					margin-right: 13px;
				}

				.price-text {
					// width: 44px;
					margin-right: 10px;
					height: 33px;
					font-size: 24px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #3370FF;
					line-height: 33px;
				}

				.popover-content {
					.pop-item {
						// display: flex !important;
						// justify-content: space-between;
						// padding: 0 10px;
					}
				}

				.popover-reference {
					display: flex;
					align-items: center;
					justify-content: space-around;
					width: 80px;
					height: 24px;
					background: popupBackgroundColor;
					border-radius: 8px;
					border: 1px solid #0065D9;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #3370FF;
					line-height: 24px;
				}
			}

			.pop-footerbuy-right {
				display: flex;

				.cancelbuy {
					// width: 102px;
					// height: 36px;
					border-radius: 4px;
					border: 1px solid @recommendMusicianListTextColor;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					color: @popupCancelTextColor;
					margin-right: 44px;
					background: @popupCancelBgColor;
					width: 40px;
					height: 36px;
					border-radius: 4px;
					cursor: pointer;

					// border: 1px solid #FFFFFF;
					img {
						width: 16px;
						height: 16px;
					}

					&:hover {
						// border: 1px solid @recommendMusicianListTextColor;
						position: relative;

						&:after {
							position: absolute;
							content: '联系版权客服';
							left: -50%;
							bottom: -30px;
							background: red;
							color: green;
							width: 92px;
							height: 27px;
							background: rgba(255, 255, 255, 0.7);
							box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
							border-radius: 4px;
							font-size: 12px;
							color: #222426;
							line-height: 27px;
							display: flex;
							justify-content: center;
						}
					}
				}

				.confirmbuy {
					width: 102px;
					height: 36px;
					background: #3278ff;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					color: #ffffff;
					cursor: pointer;
					margin-right: 20px;
				}
			}



		}

		.pop-footer {
			display: flex;
			align-items: center;
			height: 68px;
			justify-content: center;

			.cancel {
				width: 102px;
				height: 36px;
				border-radius: 4px;
				border: 1px solid @popupCancelBorderColor;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: @popupCancelTextColor;
				margin-right: 44px;
				background: @popupCancelBgColor;
			}

			.confirm {
				width: 102px;
				height: 36px;
				background: #3278ff;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: #ffffff;
			}
		}
	}
</style>
