<template>
	<div>
		<PopupCommon ref="popupPay" customClassName="textarea-modal" title=" " :visible.sync="visible" :width="'886px'">
			<div :slot="'title'" class="pop-title4">{{$t('recommend.payImmediately')}}</div>
			<!-- <div style="width:556px;height:1px;background: ;"></div> -->
			<div :slot="'content'" class="pop-content4">
				<div class="content-top">
					<pay-immediately-top :orderInfo="orderInfo"></pay-immediately-top>
				</div>
				
				<div class="content-bottom">
					<pay-immediately-bottom :orderInfo="payInfo"></pay-immediately-bottom>
				</div>
				
			</div>
			<div :slot="'footer'" class="pop-footer4">
				<div v-if="themeStyle == 'default'" class="customr-service" @click="payCustomerService">
					<a
					  href="http://kf.7keymusic.com/addons/kefu"
					  target="_blank"
					>
						<img src="@/assets/images/recommend/custom-default.png" alt="">
					</a>
				</div>
				<div v-if="themeStyle == 'dark'" class="customr-service" @click="payCustomerService">
					<a
					  href="http://kf.7keymusic.com/addons/kefu"
					  target="_blank"
					>
						<img src="@/assets/images/recommend/custom-dark.png" alt="">
					</a>
				</div>
				<div class="confirm" @click="payFinishPay">{{$t('recommend.completePayment')}}</div>
			</div>
		</PopupCommon>
	</div>
</template>

<script>
	import PopupCommon from "@/components/popup/PopupCommon.vue"
	import PayImmediatelyBottom from "@/components/content/PayImmediatelyBottom.vue"
	import PayImmediatelyTop from "@/components/content/PayImmediatelyTop.vue"
	export default {
		props: {
			orderInfo: {
				type: Object,
				default(){
					return {}
				}
			},
			payInfo: {
				type: Object,
				default(){
					return {}
				}
			}
		},
		components: {
			PopupCommon,
			PayImmediatelyBottom,
			PayImmediatelyTop
		},
		data(){
			return {
				visible: false,
				// orderInfo: {
				// 	avatar: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg13.51tietu.net%2Fpic%2F2020010611%2Fnfptpll3xzknfptpll3xzk.jpeg&refer=http%3A%2F%2Fimg13.51tietu.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1670075622&t=7a0a6d98360a03dc014ab446419bbf92',
				// 	name: 'Clarence Walters',
				// 	authorizedTerritory: '大陆',
				// 	authorizationType: '灌录权（灌录权、传播权、复制权）',
				// 	orderSn: '1234567890',
				// 	orderMoney: '30',
				// 	timeList: [{
				// 			time: '3'
				// 		},
				// 		{
				// 			time: '5'
				// 		},
				// 		{
				// 			time: '17'
				// 		}
				// 	]
				// },
				// payInfo: {
				// 	codeImg: 'https://img2.baidu.com/it/u=1989038259,1842360401&fm=253&fmt=auto&app=138&f=PNG?w=500&h=500',
				// 	time: '29分42秒',
				// 	money: '30',
				// 	isWechat: '1', //是否支持微信支付 0不支持 1支持
				// 	isApliy: '1', //是否支持支付宝支付 0不支持 1支持
				// 	isCorporateTransfer: '1', //是否支持对公转账 0不支持 1支持
				// 	corporateName: '名称：沈阳梯基音乐文化传媒有限公司',
				// 	bankDeposit: '建设银行沈阳南京街支行',
				// 	bankAccount: '21050110084600000051'
				
				// },
				themeStyle: '', //主题 default浅色 dark深色
			}
		},
		methods: {
			payCustomerService(){
				this.$refs.popupPay.close()
			},
			payFinishPay(){
				this.$refs.popupPay.close()
			},
			open(){
				this.themeStyle = localStorage.getItem("theme")
				this.$refs.popupPay.open()
			},
			// close(){
			// 	this.$refs.lockSongPopup.close()
			// }
		}
	}
</script>

<style lang="less" scoped>
	::v-deep .el-dialog {
		margin-top: 9vh !important;
	}
	// 锁定demo弹窗
	.pop-title4 {
		height: 68px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 0 25px;
		color: @recommendMusicianListTextColor;
	}
	
	.pop-content4 {
		// width: 528px;
		// width: 100%;
		
		// max-height: 86px;
		// padding: 24px;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 24px 25px;
		color: @recommendMusicianListTextColor;
		line-height: 22px;
		.content-top{
			margin-bottom: 24px;
		}
		.content-bottom{
			width: 100%;
			// height: 320px;
		}
	}
	
	.pop-footer4 {
		display: flex;
		align-items: center;
		height: 68px;
		justify-content: flex-end;
		padding-right: 20px;
		.customr-service{
			// width: 102px;
			// height: 36px;
			border-radius: 4px;
			border: 1px solid @recommendMusicianListTextColor;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: @popupCancelTextColor;
			margin-right: 44px;
			background: @popupCancelBgColor;
			width: 40px;
			height: 36px;
			border-radius: 4px;
			cursor: pointer;
			// border: 1px solid #FFFFFF;
			img{
				width: 16px;
				height: 16px;
			}
			&:hover{
				// border: 1px solid @recommendMusicianListTextColor;
				position: relative;
				&:after{
					position: absolute;
					content: '联系版权客服';
					left: -50%;
					bottom: -30px;
					background: red;
					color: green;
					width: 92px;
					height: 27px;
					background: rgba(255,255,255,0.7);
					box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
					border-radius: 4px;
					font-size: 12px;
					color: #222426;
					line-height: 27px;
					display: flex;
					justify-content: center;
				}
			}
		}
		.confirm{
			width: 102px;
			height: 36px;
			background: #3278FF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: #FFFFFF;
			cursor: pointer;
		}
	}
</style>