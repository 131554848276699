<template>
  <div class="main2" @mouseover="mouseover()" @mouseleave="mouseleave()" style="position: relative;">
    <img :src="info.images" alt="" style="position: absolute;width: 100%;height: 100%;border-radius: 6px;z-index: 1;">
    <div class="follow" @click="liking(info.musicId)" v-if="info.isFavorite == 0">
      <Icon
          v-if="themeStyle == 'default'"
          name="icon-7key-musicshoucang"
          color="#666666"
          width="18"
          height="18"
      ></Icon>
      <Icon
          v-if="themeStyle == 'dark'"
          name="icon-7key-musicshoucang"
          color="#FFFFFF"
          width="18"
          height="18"
      ></Icon>
    </div>
    <div class="follow" @click="liked(info.musicId)" v-if="info.isFavorite == 1">
      <!-- <img src="../../assets/images/recommend/heart.png" alt=""> -->
      <Icon
          name="icon-7key-musicxin"
          color="#FEC002"
          width="18"
          height="18"
      ></Icon>

    </div>
    <div class="bottom">
      <div class="title">{{ info.title }}</div>
      <div class="song">{{ $t('recommend.singer') }}：{{ info.singer }}</div>
    </div>
    <!-- 播放图标 开始 -->
    <div class="play-wrap" v-if="show == true">
      <!-- <div class="play">
        <i class="el-icon-caret-right" color="red" size="50"></i>
      </div> -->
	  <div class="play" @click="pause()"
	       v-if="$store.state.musicInfo.playStatus && info.musicId == $store.state.musicInfo.musicId">
	    <Icon name="icon-7key-music24gf-pause2" class="icon-play" color="#3370FF"/>
	  </div>
	  <div class="play" @click="play()"
	       v-else>
	    <Icon name="icon-7key-musicplayfill" class="icon-play" color="#3370FF"/>
	  </div>
    </div>

    <!-- 播放图标 结束 -->
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      show: false,
      themeStyle: '',// 主题 default默认主题 dark 深色主题
    }

  },
  mounted() {
    const that = this;
    window.addEventListener("setItemEvent", function (e) {
      // console.log(e.newValue);
      that.themeStyle = e.newValue;
    });
    this.themeStyle = localStorage.getItem("theme");
  },
  methods: {
	  play() {
	    this.$set(this.info, 'playStatus', true)
	    this.show = false
	    this.$store.commit('changeMusicInfo', this.info)
	    console.log('播放:', this.info)
	  },
	  pause() {
	    this.$set(this.info, 'playStatus', false)
	    this.show = false
	    this.$store.commit('changeMusicInfo', this.info)
	    console.log('暂停:', this.info)
	  },
    // 添加喜欢
    liking(musicId) {
      this.$emit('liking', musicId)
    },
    // 取消喜欢
    liked(musicId) {
      this.$emit('liked', musicId)
    },
    // 鼠标移入事件
    mouseover() {
      console.log('mouseover')
      this.show = true
    },
    // 鼠标移出事件
    mouseleave() {
      console.log('mouseout')
      this.show = false
    }
  }
}
</script>

<style lang="less" scoped>
.main2 {
  // width: 250px;
  width: 100%;
  height: 300px;
  background: rgba(34, 36, 38, 0.1);
  border-radius: 6px;
  position: relative;

  .follow {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 34;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .bottom {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    color: #fff;
    height: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;

    .title {
      margin-bottom: 7px;
    }
  }

  .play-wrap {
    width: 100%;
    height: 100%;
    border-radius: 6px 6px 0 0;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    z-index: 33;
    display: flex;
    align-items: center;
    justify-content: center;

    .play {
      position: absolute;
      z-index: 4;
      right: 16px;
      bottom: 16px;
      color: #3370FF;
      font-size: 40px;
      width: 72px;
      height: 72px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.8);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: RGBA(169, 173, 175, 0.8);
	  .icon-play{
	  	margin-top: -6px;
	  }
    }
  }

}
</style>