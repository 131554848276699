import { render, staticRenderFns } from "./RecommendTitle.vue?vue&type=template&id=04c9b372&scoped=true"
import script from "./RecommendTitle.vue?vue&type=script&lang=js"
export * from "./RecommendTitle.vue?vue&type=script&lang=js"
import style0 from "./RecommendTitle.vue?vue&type=style&index=0&id=04c9b372&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c9b372",
  null
  
)

export default component.exports